<template>
  <v-footer
    id="pages-core-footer"
    absolute
    color="transparent"
    dark
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center"
          cols="6"
          md="auto"
        >
          <a
            :href="link.href"
            class="mr-0"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2021, Avec
            <v-icon size="20">
              mdi-heart-outline
            </v-icon>
            par <a
              href="https://www.connexence.com"
              target="_blank"
              class="lienBlanc"
            >Connexence</a>.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<style scoped>
a.lienBlanc:visited{
  color:white !important
}
</style>

<script>
  export default {
    name: 'PagesCoreFooter',

    data: () => ({
      links: [
        // {
        //   href: 'https://www.connexence.com',
        //   text: 'Connexence',
        // },
        // {
        //   href: '#',
        //   text: 'A propos',
        // },
        // {
        //   href: '#',
        //   text: 'Blog',
        // },
        // {
        //   href: '#',
        //   text: 'Partenaires',
        // },
      ],
    }),
  }
</script>

<style lang="sass">
  #pages-core-footer
    a
      color: #FFFFFF
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
